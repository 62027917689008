import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Typography,
  Button,
  TextField
} from '@mui/material';

import moment from 'moment';
import 'moment/locale/ko'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EditorInput from '../../components/inputs/EditorInput';

moment.locale('ko')

function EventAdminCreatePage(props){

  const [editorText, setEditorText] = useState('');
  const [eventData, setEventData] = useState({
    title : '',
    description : '',
    range_start : moment(),
    range_end : moment()
  });

  const handleEventTitle = (event) => {
    setEventData((prev) => ({...prev, title:event.target.value}))
  };

  const handleEventStartDate = (value) => (event) => {
    setEventData((prev) => ({...prev, range_start :event.target.value}))
  };

  const handleEventEndDate = (value) => (event) => {
    setEventData((prev) => ({...prev, range_end :event.target.value}))
  };

  return (
    <Box sx={{
      position:'relative',
      width:'100%',
      textAlign:'left',
      boxSizing:'border-box'}}>
      <Grid container
        sx={{width:'100%', position:'relative'}}>
        <Grid item md={12}
          sx={{boxSizing:'border-box', padding:'36px'}}>
          <Typography
            sx={{
              fontSize:'24px',
              marginBottom:'16px',
              fontWeight:'900', textAlign:'left'}}>
            새로운 이벤트 추가하기
          </Typography>
        </Grid>
        <Grid item
          md={6}>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              이벤트 제목
            </Typography>
            <TextField
              required
              id="outlined-required"
              sx={{maxWidth:'420px', width:'100%', position:'relative'}}
              label="제목"
              onChange={handleEventTitle}
              value={eventData.title}/>
          </Box>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              이벤트 기간
            </Typography>
            <DatePicker
               label="이벤트 시작일"
               renderInput={(params) => <TextField {...params} />}
               value={eventData.range_start}
              />
          </Box>
        </Grid>
        <Grid item
          md={6}
          sx={{padding:'16px'}}>
          <Typography
            sx={{fontSize:'18px', fontWeight:'900', textAlign:'left'}}>
            이벤트 내용
          </Typography>
          <Box sx={{
            width:'100%',
            position:'relative',
            boxSizing:'border-box',
            padding:'16px 0px', height:'500px'}}>
            <EditorInput
              value={editorText}
              onChange={setEditorText}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventAdminCreatePage;
