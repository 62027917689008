import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import useToken from './utils/useToken'
import { useNavigate } from 'react-router-dom';

import {
  SignedRoute,
  NonSignedRoute,
  PreviousRoute
} from './routes'

function App(props) {

  const { token, removeToken, setToken } = useToken();

  const handleRemoveToken = () => {
    removeToken();
  }

  return (
    <div className="App" >
     <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter history={props.history}>
          {(props.__version === "V2") ? ((!token && token !== "" && token!== undefined) ?
          <NonSignedRoute setToken={setToken}/>
          :
          <SignedRoute { ...props } token={token} setToken={setToken} onRemoveToken={handleRemoveToken}/>)
          : <PreviousRoute { ...props } />}
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

App.propTypes = {
  history: PropTypes.object
}

export default App;
