import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';

import Typography  from '@mui/material/Typography';
import Button  from '@mui/material/Button';

import axios from "axios";

function LoginPage(props){

  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    id: '',
    password: '',
    showPassword: false,
  });

  const [viewErrors, setViewErrors] = React.useState({
    id: false,
    password: false
  });

  const handleChange = (prop) => (event) => {
    handleViewErrors("id", false);
    handleViewErrors("password", false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleViewErrors = (prop, error) => {
    setViewErrors({ ...viewErrors, [prop]: error });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' && e.target.value) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let valid = true;
    if(values.id.length === 0){
      handleViewErrors("id", true);
      valid = false;
    }
    if(values.password.length === 0){
      handleViewErrors("password", true);
      valid = false;
    }
    if(!valid) return;
    requestLogin();
  };

  function requestLogin() {
     axios({
       method: "POST",
       url:"/api/token",
       data:{
         id: values.id,
         password: values.password
        }
     })
     .then((response) => {
       props.setToken(response.data.access_token);
       navigate("/dashboard");
     }).catch((error) => {
       if (error.response) {
         console.log(error.response)
         console.log(error.response.status)
         console.log(error.response.headers)
         }
     });
  }
  return (
    <>
      <Container
        sx={{
          height:"100vh",
          maxWidth:"100vw !important",
          backgroundColor:"#000000"
        }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height:"100vh"
          }}>
          <Paper
            elevation={6}
            sx={{
              width:'300px',
              padding:'64px 24px',
              backgroundColor:"#fff",
              borderRadius:'16px'
            }}>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center">
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  mb:"0px",
                  lineHeight:1,
                  color:'#01579B',
                  fontSize:'48px',
                  fontWeight:'700'
                }}>
                <img
                  style={{height:'36px', cursor:'pointer'}} src="/icons/eaudevie_italic_black.png" />
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{
                  mt:'0px !important',
                  mb:"48px",
                  color:'#01579B',
                  fontSize:'14px',
                  fontWeight:'300'
                }}>
                Enhanced product management system
              </Typography>
              <TextField
                id="outlined-password-input"
                label="ID"
                size="small"
                error={viewErrors.id}
                sx={{
                  width:"100%",
                  maxWidth:"250px",
                  mt:'24px !important'
                }}
                helperText={viewErrors.id ? "ID를 입력해주세요" : ""}
                onKeyDown={handleKeyDown}
                onChange={handleChange('id')}
                autoComplete="off"/>
              <FormControl sx={{
                  width:"100%",
                  maxWidth:"250px"
                }} variant="outlined"
                error={viewErrors.password}
                size="small">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  aria-describedby="component-error-text"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {viewErrors.password && (<FormHelperText id="component-error-text">패스워드를 입력해주세요</FormHelperText>)}
              </FormControl>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  width:"100%",
                  maxWidth:"250px"}}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleSubmit}>로그인</Button>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  mt : "2px !important",
                  width:"100%",
                  maxWidth:"250px"}}>
                <Button
                  size="small"
                  sx={{fontSize:"12px", color:"#888"}}
                  variant="text">비밀번호를 잊어버렸어요</Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </>
  );
}

export default LoginPage;
